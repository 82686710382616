<template>
  <div class="container">
    <div class="page-sub-box ewm">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <div class="page_search_box line_bottom_1px">
          <div class="flex space-x-2 inner">
            <div class="mt-2">등록일</div>
            <DxDateBox
              :edit-enabled="false"
              :styling-mode="stylingMode"
              width="110"
              height="30"
              v-model="searchType.customTypes.startDt"
              type="date"
              display-format="yyyy.MM.dd"
              dateSerializationFormat="yyyyMMdd"
              dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
              :max="searchType.customTypes.endDt"
              maxLength="7"
              invalid-date-message="입력 데이터가 유효하지 않습니다."
            >
              <DxValidator validation-group="validationSearch">
                <DxRequiredRule message="기준년월은 필수입니다." />
              </DxValidator>
            </DxDateBox>
            <div class="mt-1">~</div>
            <DxDateBox
              :styling-mode="stylingMode"
              width="110"
              height="30"
              v-model="searchType.customTypes.endDt"
              type="date"
              display-format="yyyy.MM.dd"
              dateSerializationFormat="yyyyMMdd"
              dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
              maxLength="7"
              :min="searchType.customTypes.startDt"
              invalid-date-message="입력 데이터가 유효하지 않습니다."
            >
              <DxValidator validation-group="validationSearch">
                <DxRequiredRule message="기준년월은 필수입니다." />
              </DxValidator>
            </DxDateBox>
            <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectDataList" />
          </div>
        </div>
      </div>
      <esp-dx-data-grid :data-grid="dataGrid" ref="addscoreGrid" @init-new-row="onInitNewRow" @saving="e => onSave(e)" />
    </div>
  </div>
</template>

<script>
  import { DxSwitch } from 'devextreme-vue/switch';
  import { DxButton } from 'devextreme-vue/button';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
  import validationEngine from 'devextreme/ui/validation_engine';
  import { getPastFromToday, isSuccess } from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = this;

  export default {
    components: {
      EspDxDataGrid,
      DxButton,
      DxDateBox,
      DxSelectBox,
      DxRequiredRule,
      DxValidator,
    },
    data() {
      return {
        codeMap: {},
        codes: {
          addscoreTypeId: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
          addscoreTypeDetailId: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
        },
        config: {
          pageSetting: {
            //pageData pageSetting 관련
            config: {},
          },
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            startDt: getPastFromToday(11, 'months'),
            endDt: getPastFromToday(-1, 'months'),
          },
          paramsData: {},
        },
        selectionChanged: false,
        dataGrid: {
          keyExpr: 'id',
          refName: 'addscoreGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          columnResizingMode: 'widget',
          columnAutoWidth: true,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: 'calc(100vh - 310px)', // 주석처리시 100%
          scrolling: {
            mode: 'standard',
          },
          callApi: 'CALL_EWM_API',
          apiActionNm: {},
          customEvent: {
            cellHoverChanged: false,
            cellPrepared: false,
            checkDataBeforeSaving: false,
            initNewRow: true,
            rowInserted: false,
            rowInserting: false,
            saving: true,
          },
          showActionButtons: {
            copy: false,
            select: false,
            update: false,
            delete: false,
            excel: false,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick() {
                    vm.onDeleteData();
                  },
                },
                location: 'before',
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
            operationDescriptions: {
              contains: '포함',
            },
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: options => {
              if (options.row.rowType == 'data' && options.row.data.useFl === this.$_enums.common.stringUsedFlag.YES.value) {
                return false;
              } else {
                return true;
              }
            }, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '가감점구분',
              dataField: 'addscoreTypeId',
              width: 130,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              allowHeaderFiltering: true,
              lookup: {},
              requiredRule: {
                message: '필수 항목입니다.',
              },
              calculateSortValue: data => {
                return this.codes.addscoreTypeId.dataSource.find(e => data.addscoreTypeId == e.codeId).codeNm;
              },
            },
            {
              caption: '가감점상세구분',
              dataField: 'addscoreTypeDetailId',
              width: 140,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              allowHeaderFiltering: true,
              editCellTemplate: (container, options) => {
                const selectBox = new DxSelectBox({
                  propsData: {
                    dataSource: this.codes.addscoreTypeDetailId.dataSource,
                    displayExpr: 'codeNm',
                    valueExpr: 'codeId',
                    value: options.data.addscoreTypeDetailId,
                    stylingMode: this.stylingMode,
                    onValueChanged: e => {
                      options.value = e.value;
                      vm.selectionChanged = true;
                    },
                    onDisposing: () => {
                      if (vm.selectionChanged) {
                        options.component.cellValue(
                          options.rowIndex,
                          'addscoreName',
                          this.codes.addscoreTypeDetailId.dataSource.find(f => f.codeId == options.value).codeNm,
                        );
                        options.component.cellValue(options.rowIndex, 'addscoreTypeDetailId', options.value);
                        vm.selectionChanged = false;
                      }
                    },
                  },
                });
                selectBox.$mount();
                if (options.data.addscoreTypeId == 1317) {
                  container.append(selectBox.$el);
                } else {
                  options.data.addscoreTypeDetailId = options.data.addscoreTypeId;
                }
              },
              lookup: {},
              requiredRule: {
                message: '필수 항목입니다.',
              },
              calculateSortValue: data => {
                const findVal = this.codes.addscoreTypeDetailId.dataSource.find(e => data.addscoreTypeDetailId == e.codeId);
                return findVal ? findVal.codeNm : '';
              },
            },
            {
              caption: '사유명',
              dataField: 'addscoreName',
              height: 40,
              visible: true,
              allowEditing: true,
              allowHeaderFiltering: false,
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '가감점',
              dataField: 'addscore',
              width: 100,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              allowHeaderFiltering: false,
              cellTemplate: function (container, options) {
                const classStyle = document.createElement('div');

                if (options.value > 0 || options.value < 0) {
                  const color = options.value > 0 ? 'blue' : 'red';

                  classStyle.style.color = color;
                }

                classStyle.innerText = options.value;

                container.append(classStyle);
              },
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '표시여부',
              dataField: 'viewFl',
              width: 130,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              calculateSortValue: data => {
                return this.$_enums.common.stringUsedFlag.values.find(e => data.viewFl == e.value).label;
              },
              cellTemplate: (container, options) => {
                const switchBtn = new DxSwitch({
                  propsData: {
                    value: options.data.viewFl === this.$_enums.common.stringUsedFlag.YES.value,
                    disabled: options.data.useFl === this.$_enums.common.stringUsedFlag.YES.value ? true : false,
                    //readOnly: true,
                    onValueChanged: e => {
                      const viewFlVal = e.value
                        ? this.$_enums.common.stringUsedFlag.YES.value
                        : this.$_enums.common.stringUsedFlag.NO.value;
                      this.$refs.addscoreGrid.getGridInstance.cellValue(options.rowIndex, 'viewFl', viewFlVal);
                    },
                  },
                });
                switchBtn.$mount();
                container.append(switchBtn.$el);
              },
              /* editCellTemplate: (container, options) => {
							const switchBtn = new DxSwitch({
								propsData: {
									value: options.data.viewFl === this.$_enums.common.stringUsedFlag.YES.value,
									disabled: options.data.useFl === this.$_enums.common.stringUsedFlag.YES.value ? true : false,
									onValueChanged: e => {
										const viewFlVal = e.value
											? this.$_enums.common.stringUsedFlag.YES.value
											: this.$_enums.common.stringUsedFlag.NO.value;
										options.setValue(viewFlVal);
									},
								},
							});
							switchBtn.$mount();
							container.append(switchBtn.$el);
						}, */
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '사용여부',
              dataField: 'useFl',
              width: 130,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
              calculateSortValue: data => {
                return this.$_enums.common.stringUsedFlag.values.find(e => data.useFl == e.value).label;
              },
            },
            {
              caption: '등록자',
              dataField: 'regIdAndNm',
              width: 130,
              height: 40,
              alignment: 'center',
              allowFiltering: true,
              allowHeaderFiltering: false,
              allowEditing: false,
              calculateDisplayCellValue: rowData => {
                return `${rowData.regNm}[${rowData.regId}]`;
              },
            },
            {
              caption: '등록일',
              dataField: 'regDt',
              width: 130,
              height: 40,
              alignment: 'center',
              allowFiltering: true,
              allowHeaderFiltering: false,
              allowEditing: false,
              calculateDisplayCellValue: rowData => {
                return this.$_commonlib.formatDate(rowData.regDt, 'YYYYMMDD', 'YYYY.MM.DD');
              },
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /** @description : 데이터 추가 시 기본 값 적용하기 위함 */
      onInitNewRow(e) {
        e.data.addscore = null;
        e.data.viewFl = 'Y';
        e.data.useFl = 'N';
        e.data.delFl = 'N';
        e.data.regId = this.$store.getters.getLoginId;
        e.data.regNm = this.$store.getters.getLoginNm;
        e.data.regDt = new Date();
      },
      /** @description : 저장 */
      async onSave(e) {
        e.cancel = true;

        if (
          !(await this.$_Confirm(this.$_msgContents('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), {
            title: `<h2>알림</h2>`,
          }))
        ) {
          return;
        }

        if (!e.changes.length) {
          return;
        }

        let bfData = [];
        if (e.changes && e.changes.length > 0) {
          e.changes.forEach(d => {
            if (d.type === 'update') {
              let rowIndex = e.component.getRowIndexByKey(d.key);
              let row = e.component.getVisibleRows()[rowIndex];
              let updateData = row.data;

              bfData.push(updateData);
            } else if (d.type === 'insert') {
              if (Object.keys(d.data).length !== 0) {
                let updateData = { ...d.data };
                updateData['id'] = null;

                bfData.push(updateData);
              }
            }
          });
        }

        let data = [];
        bfData.forEach(d => {
          data.push({
            id: d.id,
            addscoreTypeId: d.addscoreTypeDetailId,
            addscoreName: d.addscoreName,
            addscore: d.addscore,
            useFl: d.useFl,
            viewFl: d.viewFl,
          });
        });

        const payload = {
          actionname: 'EWM_ADDSCORE_SAVE',
          data: { data: data },
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          this.selectDataList();
        } else {
          this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description : 삭제 */
      async onDeleteData() {
        const selectedRowsData = this.$refs.addscoreGrid.selectedRowsData;

        const existUseFl = selectedRowsData.findIndex(element => element.useFl === 'Y');

        if (existUseFl > -1) {
          this.$_Msg(`사용중인 기준이 있습니다. '${selectedRowsData[existUseFl].addscoreName}' 관리표를 확인하세요.`);
          return;
        }

        const sendData = selectedRowsData.map(x => x.id);

        if (!selectedRowsData?.length) {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        } else {
          if (!(await this.$_Confirm(this.$_msgContents('CMN_CFM_DELETE_SELECTED'), { title: `<h2>알림</h2>` }))) {
            return;
          }

          const payload = {
            actionname: 'EWM_ADDSCORE_DELETE',
            data: sendData,
            loading: true,
          };

          const res = await this.CALL_EWM_API(payload);

          if (isSuccess(res)) {
            this.$_Toast(this.$_msgContents('CMN_SUC_DELETE'));
            this.selectDataList();
          } else {
            this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          }
        }
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList() {
        if (!validationEngine.validateGroup('validationSearch').isValid) {
          return;
        }

        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          async load() {
            const payload = {
              actionname: 'EWM_ADDSCORE_LIST',
              data: {
                startDt: vm.searchType.customTypes.startDt,
                endDt: vm.searchType.customTypes.endDt,
              },
              loading: false,
            };

            const res = await vm.CALL_EWM_API(payload);
            if (isSuccess(res)) {
              res.data.data.forEach(r => {
                r.addscoreTypeDetailId = r.addscoreTypeId;
                if (r.addscoreTypeId > 1317) {
                  r.addscoreTypeId = 1317;
                }
              });

              const rtnData = {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };

              console.log('this.filterData', this.filterData);

              vm.$refs.addscoreGrid.totalCount = rtnData.totalCount;

              vm.$refs.addscoreGrid.getGridInstance.cancelEditData();

              return rtnData;
            }
          },
        });
      },
      /** @description : 셀렉트 박스 초기화 */
      async initCodeMap() {
        this.codeMap = await this.$_getCodeMapList('root_ewm_kpi_addscore_type');
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        vm = this;

        this.initCodeMap().then(() => {
          this.codes.addscoreTypeId.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_kpi_addscore_type'], 2);
          this.codes.addscoreTypeDetailId.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_kpi_addscore_type'], 3);

          this.$refs.addscoreGrid.getGridInstance.columnOption('addscoreTypeId', 'lookup', this.codes.addscoreTypeId);
          this.$refs.addscoreGrid.getGridInstance.columnOption('addscoreTypeDetailId', 'lookup', this.codes.addscoreTypeDetailId);
        });
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      mountData() {
        this.selectDataList();
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>

<style scoped>
  .page_search_box .inner div {
    display: inline-block;
  }
  .page_search_box .inner > div:not(.box-btn-search) {
    vertical-align: middle;
    margin-right: 10px;
  }
  .page_search_box .spec-type-box {
    display: inline-block;
  }
  .page_search_box .inner .box-btn-search {
    margin-right: 15px;
  }
  .page_search_box #searchTooltip {
    margin-right: 5px;
    font-size: 22px;
    color: #808080;
  }
</style>
